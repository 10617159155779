var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.rules == 'none' ? '' : _vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{class:_vm.fieldClass,attrs:{"type":{
      'is-danger': errors[0],
      'is-success': valid,
      'is-pasivo': _vm.pasivo == true
    },"label":_vm.label,"message":errors}},[_c('b-autocomplete',{attrs:{"placeholder":_vm.placeholder,"keep-first":true,"field":_vm.show,"append-to-body":_vm.appendBody,"open-on-focus":true,"data":_vm.dataFiltered,"icon-right":_vm.icon ? _vm.icon : '',"disabled":_vm.disabled,"clearable":_vm.clearable,"clear-on-select":_vm.clearOnSelect},on:{"select":_vm.emitSelect,"typing":_vm.emitTyping},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("custom",[_vm._v(" "+_vm._s(props.option[_vm.show])+" ")],{"option":props})]}}],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_c('template',{slot:"empty"},[_vm._v("No hay resultados")])],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }