<template>
  <div class="preview bg-white shadow-capvital has-border-radius p-4 text-left">
    <b-button
      icon-left="times"
      type="is-text"
      class="absolute right-0 mr-4 top-0 mt-4"
      @click="closePreview"
    />
    <icon-initials
      v-if="tipo == 'persona'"
      :nombre="titulo"
      :tipo="info.tipos_persona_id"
    />
    <b-icon
      :icon="icon"
      size="is-large"
      class="p-2 rounded-full has-background-white"
      v-else-if="icon != null"
    />
    <p class="text-left text-2xl truncate mt-2" :class="title_color">
      {{ titulo }}
    </p>
    <div
      v-if="
        tipo == 'credito' &&
          info.status.status.activo == 0 &&
          info.status.nombre != 'Finalizado'
      "
      class="py-20"
    >
      <h1 class="text-center">
        Sin información para mostrar ya que esta disposición aún no ha sido
        entregada.
      </h1>
    </div>
    <p class="mb-3" v-if="total">{{ total | currency }} {{ this.moneda }}</p>
    <div class="font-normal">
      <div v-for="(field, index) in fields" :key="index" class="mb-1">
        <p class="has-text-grey-light">{{ field.field }}</p>
        <p :class="text_color" v-if="field.type == 'currency'">
          {{ field.value | currency }} {{ field.moneda }}
        </p>
        <p :class="text_color" v-else-if="field.type == 'date'">
          <span v-if="field.value != 'N/A'">
            {{ field.value | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
        <p :class="[field.class]" class="-mt-1" v-else>
          {{ field.value }}
        </p>
      </div>
    </div>
    <div class="my-3" v-if="tipo == 'cuentaCP'">
      <b-button
        type="is-primary"
        class="font-regular w-full"
        tag="router-link"
        @click.native="closePreview"
        :to="{
          name: 'aplicarpago',
          params: {
            tipo: 'cuentaCP',
            idObjeto: info.id
          }
        }"
      >
        Aplicar pago
      </b-button>
    </div>
    <div
      class="my-3"
      v-if="
        tipo == 'credito' &&
          info.status.status.activo == 1 &&
          modulo_caja.activo == 1
      "
    >
      <b-button
        type="is-primary"
        class="font-regular w-full"
        tag="router-link"
        @click.native="closePreview"
        :to="{
          name: 'aplicarpago',
          params: {
            tipo: 'disposicion',
            idObjeto: info.id
          }
        }"
      >
        Aplicar pago
      </b-button>
    </div>
    <div class="mt-3" v-if="btn_enabled">
      <b-button :type="btn_type" expanded tag="a" @click="goTo(link)"
        >Ver en detalle</b-button
      >
    </div>
  </div>
</template>

<script>
import IconInitials from "@/components/generals/IconInitials";
import config from "@/../config";
import { goTo } from "@/vendors/helpers";
import { mapGetters } from "vuex";

export default {
  name: "ContentPreview",
  components: {
    IconInitials
  },
  props: {
    info: Object,
    tipo: String,
    clase: String,
    id: Number,
    saldos: [Object, null],
    moneda: String,
    pasivo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: null,
      titulo: null,
      icon: "info",
      fields: [],
      icon_class: "has-background-grey-lighter has-text-grey",
      text_color: "has-text-grey-darker",
      btn_type: "is-dark",
      link: null,
      total: null,
      title_color: "has-text-grey-darker",
      btn_enabled: true
    };
  },
  computed: {
    ...mapGetters(["getMe"]),
    modulo_caja() {
      const tesoreria = this.getMe
        ? this.getMe.modulos.find(modulo => modulo.nombre == "Tesoreria")
        : null;
      return tesoreria
        ? tesoreria.submodulos.find(submodulo => submodulo.nombre == "Caja")
        : null;
    }
  },
  watch: {
    info: function() {
      this.setData();
    }
  },
  methods: {
    /*
      Define los campos a mostrar dependiendo del tipo de objeto del cual se esta visualizando el preview
    */
    setData() {
      if (this.position == "full") {
        this.icon_class = "has-background-primary-04 has-text-primary";
      }
      switch (this.tipo) {
        case "linea":
          this.titulo = "Línea de crédito #" + this.id;
          this.icon = "hand-holding-usd";
          this.link = `/#/linea/${this.id}`;
          this.fields = [
            {
              field: "Fuente de fondeo",
              value: this.info.fuente_de_fondeo
                ? this.info.fuente_de_fondeo.alias
                : "N/A"
            },
            {
              field: "Acreditado",
              value: this.info.acreditado ? this.info.acreditado.alias : "N/A"
            },
            {
              field: "Ejecutivo",
              value: this.info.empleado ? this.info.empleado.alias : "N/A"
            },
            {
              field: "Producto financiero",
              value: this.info.producto_financiero.alias
            },
            {
              field: "Capital autorizado",
              value: this.info.capital_autorizado,
              type: "currency",
              moneda: this.info.moneda.clave
            },
            {
              field: "Capital disponible",
              value: this.info.capital_disponible,
              type: "currency",
              moneda: this.info.moneda.clave
            },
            {
              field: "Estatus",
              value: this.info.status_linea.nombre
            }
          ];
          break;
        case "persona":
          this.link = `${this.config.client}/#/administracion/personas/ver/perfiles/${this.id}`;
          this.titulo = this.info.alias;
          this.fields = [
            {
              field: "Tipo de persona",
              value: this.info.tipo_persona ? this.info.tipo_persona.nombre : this.info.tipo.split('_').map(string => string.charAt(0).toUpperCase() + string.slice(1)).join(' ')
            },
            {
              field: "Teléfono",
              value: this.info.telefono ? this.info.telefono : "N/A",
              class: "underline"
            },
            {
              field: "Correo",
              value: this.info.correo || "N/A",
              class: "underline"
            },
            {
              field: "RFC",
              value: this.info.persona_moral
                ? this.info.persona_moral.rfc
                : this.info.persona_fisica.rfc
            },
            {
              field: "Nacionalidades",
              value:
                this.info.nacionalidades.length > 0
                  ? this.info.nacionalidades.map(x => x.OfficialName).join(", ")
                  : "N/A"
            },
            {
              field: "Actividad economica",
              value: this.info.actividadEconomica
                ? this.info.actividadEconomica.nombre
                : "N/A"
            },
            {
              field: "Localidad",
              value: this.info.localidad ? this.info.localidad.nombre : "N/A"
            },
            {
              field: "Riesgo actual",
              value: this.info.riesgo_actual
                ? this.info.riesgo_actual.riesgo
                : "N/A",
              class: this.info.riesgo_actual
                ? this.info.riesgo_actual.riesgo == "Bajo"
                  ? "has-text-success"
                  : this.info.riesgo_actual.riesgo == "Medio"
                  ? "has-text-warning"
                  : "has-text-danger"
                : "N/A"
            }
          ];
          break;
        case "garantia":
          this.titulo = "Garantía #" + this.id;
          this.link = `${this.config.client}/#/administracion/garantias/detalles/${this.id}`;
          this.icon =
            this.info.tipo_garantia.parent_tipos_garantia_id == 1 ||
            this.info.tipo_garantia.parent_tipos_garantia_id == 2
              ? "dollar-sign"
              : "university";
          this.fields = [
            {
              field: "Tipo de garantía",
              value: this.info.tipo_garantia.nombre
            },
            {
              field: "Valor",
              value: this.info.valor,
              type: "currency"
            },
            {
              field: "Valor verificado",
              value: this.info.valor_verificado,
              type: "currency"
            },
            {
              field: "Fecha de reclamo",
              value: this.info.fecha_reclamo || "N/A",
              type: "date"
            },
            {
              field: "Fecha de vencimiento",
              value: this.info.fecha_vencimiento || "N/A",
              type: "date"
            },
            {
              field: "Descripción",
              value: this.info.descripcion || "N/A",
              class: "truncate-3"
            }
          ];
          break;
        case "cuentaCP":
          this.titulo = "Cuenta #" + this.id;
          this.link = `${this.config.client}/#/administracion/tesoreria/cuentas_por_cobrar/${this.id}`;
          this.icon = "money-check-alt";
          this.fields = [
            {
              field: "Tipo de cuenta",
              value: this.info.cobro ? "Cobrar" : "Pagar"
            },
            {
              field: "Cantidad",
              value: this.info.cantidad,
              type: "currency",
              moneda: this.info.moneda.clave
            },
            {
              field: "IVA",
              value: this.info.iva,
              type: "currency"
            },
            {
              field: "Operación",
              value: this.info.operacion.nombre
            },
            {
              field: "Fecha de vencimiento",
              value: this.info.fecha_vencimiento || "N/A",
              type: "date"
            },
            {
              field: "Fecha de liquidación",
              value: this.info.fecha_liquidacion || "N/A",
              type: "date"
            },
            {
              field: "Notas",
              value: this.info.notas,
              class: "truncate-3"
            }
          ];
          break;
        case "credito":
          this.titulo = "Disposición #" + this.info.idToShow;
          this.icon = "wallet";
          this.link = `/#/disposicion/${this.id}`;
          this.btn_type = this.info.pasivo ? "is-pasivo" : "is-primary";
          this.icon_class = this.info.pasivo
            ? "has-background-pasivo-04 has-text-pasivo"
            : "has-background-primary-04 has-text-primary";
          this.text_color = this.info.pasivo
            ? "has-text-pasivo"
            : "has-text-primary";
          this.fields = [
            {
              field: "Cartera",
              value: this.info.pasivo ? "Pasiva" : "Activa"
            },
            {
              field: "Producto financiero",
              value: this.info.producto_financiero.alias
            },
            {
              field: "Folio fondeador",
              value: this.info.pasivo 
                ? this.info.disposicion.identificador_fondeador 
                : (this.info.disposicion.disposicion_fondeos[0] ? this.info.disposicion.disposicion_fondeos[0].disposicion.identificador_fondeador : 'No disponible'),
            },
            {
              field: "Fecha de entrega",
              value: this.info.fecha_entrega
                ? this.info.fecha_entrega
                : "Sin entregar"
            },
            {
              field: "Fecha de vencimiento",
              value: this.info.fecha_vencimiento
                ? this.info.fecha_vencimiento
                : "--"
            },
            {
              field: "Saldo insoluto",
              value: this.info.saldo_insoluto,
              type: "currency",
              moneda: this.info.moneda.clave
            },
            {
              field: "Capital otorgado",
              value: this.info.capital_otorgado,
              type: "currency",
              moneda: this.info.moneda.clave
            },
            {
              field: "Estatus",
              value: this.info.status.nombre
            }
          ];
          break;
        case "pago":
          this.titulo = "Pago #" + this.id;
          this.link = `/#/pago/${this.id}`;
          this.icon = "credit-card";
          this.fields = [
            {
              field: "Tipo de operación",
              value: this.info.tipoOperacion.nombre
            },
            {
              field: "Instrumento monetario",
              value: this.info.instrumento_monetario.nombre
            },
            {
              field: "Fecha de pago",
              value: this.info.fecha_contable.date,
              type: "date"
            },
            {
              field: "Cantidad pagada",
              value: this.info.cantidad_pagada,
              type: "currency",
              moneda: this.info.moneda.clave
            },
            {
              field: "Moneda",
              value: this.info.moneda.nombre
            }
          ];
          break;
        case "saldo_vigente":
          this.titulo = "Vigente";
          this.title_color = this.pasivo
            ? "has-text-pasivo"
            : "has-text-primary";
          this.icon = null;
          this.total = this.saldos.vigente;
          this.btn_enabled = false;
          this.fields = [
            {
              field: "Capital",
              value: this.saldos.capital_vigente.valor,
              type: "currency",
              moneda: this.moneda
            },
            {
              field: "Interés ordinario",
              value: this.saldos.interes_ordinario_vigente.valor,
              type: "currency",
              moneda: this.moneda
            },
            {
              field: "Interés capitalizado",
              value: this.saldos.interes_refinanciado_vigente.valor,
              type: "currency",
              moneda: this.moneda
            }
          ];
          break;
        case "saldo_vencido":
          this.titulo = "Vencido";
          this.title_color = "has-text-danger";
          this.icon = null;
          this.btn_enabled = false;
          this.total = this.saldos.vigente;
          this.fields = [
            {
              field: "Vencido exigible",
              value: this.saldos.vencido_exigible,
              type: "currency",
              moneda: this.moneda
            },
            {
              field: "Vencido no exigible",
              value: this.saldos.vencido_no_exigible,
              type: "currency",
              moneda: this.moneda
            },
            {
              field: "Vencido por traspasar",
              value: this.saldos.vencido_por_traspasar,
              type: "currency",
              moneda: this.moneda
            }
          ];
          break;
        default:
          this.titulo = "Preview #" + this.id;
          break;
      }
      if(this.tipo == 'cuentaCP'){
        this.extendDetail();
      }
    },
    extendDetail(){
      let origin_value =''
      switch (true) {
        case this.info.garantia !== null:
          origin_value = "Garantia #"+this.info.garantia.id;
          break;
        case this.info.linea_credito !== null:
          origin_value = "Linea #"+this.info.linea_credito.id;
          break;
        case this.info.credito_core !== null:
          origin_value = "Disposicion #"+this.info.credito_core.folio;
          break;
      
        default:
          break;
      }
      this.fields.push(
        {
              field: "Origen",
              value: origin_value
        }
      )
    },
    closePreview() {
      this.$store.dispatch("helpers/closePreview");
      this.$store.dispatch("clearBusqueda");
    },
    goTo(url) {
      goTo(url);
    },
    goToPagoCP(cuenta) {
      let url =
        process.env.VUE_APP_CLIENT +
        "#/administracion/tesoreria/" +
        (cuenta.cobro === 1 ? "cobrar" : "pagar") +
        "_cuenta/" +
        cuenta.id;
      goTo(url);
    }
  },
  mounted() {
    this.setData();
  },
  created() {
    this.config = config;
  }
};
</script>
