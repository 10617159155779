import PersonasApi from "@/api/personas.api";

const state = {
  garantias: null,
  garantias_grupo: null,
  permisos_submodulo: null,
  relaciones: null,
  relaciones_grupo: null,
  detalles: null,
  acreditadosSinFondeo: null,
  creditosPersona: null,
  persona_cuentas_bancarias: null
};
const getters = {
  getGarantias: state => state.garantias,
  getGarantiasGrupo: state => state.garantias_grupo,
  getRelaciones: state => state.relaciones,
  getRelacionesGrupo: state => state.relaciones_grupo,
  getDetalles: state => state.detalles,
  getAcreditadosSinFondeo: state => state.acreditadosSinFondeo,
  getCreditosPersona: state => state.creditosPersona,
  getPersonaCuentasBancarias: state => state.persona_cuentas_bancarias,
};
const mutations = {
  SET_DETALLES(state, detalles) {
    state.detalles = detalles;
  },
  SET_GARANTIAS(state, garantias) {
    state.garantias = garantias;
  },
  SET_GARANTIAS_GRUPO(state, garantias) {
    state.garantias_grupo = garantias;
  },
  SET_RELACIONES(state, relaciones) {
    state.relaciones = relaciones;
  },
  SET_RELACIONES_GRUPO(state, relaciones) {
    state.relaciones_grupo = relaciones;
  },
  ADD_RELACION(state, relacion) {
    state.relaciones.push(relacion);
  },
  SET_ACREDITADOS_SIN_FONDEOS(state, acreditados) {
    state.acreditadosSinFondeo = acreditados;
  },
  SET_CREDITOS_PERSONA(state, creditos) {
    state.creditosPersona = creditos;
  },
  SET_CUENTAS_BANCARIAS(state, cuentas) {
    state.persona_cuentas_bancarias = cuentas;
  },
};
const actions = {
  getDetallesPersona({ commit }, persona_id) {
    return PersonasApi.getDetallesPersona(
      persona_id,
      res => {
        commit("SET_DETALLES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getAcreditadosActivosSinFondeo({ commit }) {
    return PersonasApi.getAcreditadosActivosSinFondeo(
      res => {
        commit("SET_ACREDITADOS_SIN_FONDEOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getGarantias({ commit }, persona_id) {
    return PersonasApi.getGarantias(
      persona_id,
      res => {
        commit("SET_GARANTIAS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getGarantiasGrupo({ commit }, grupo_id) {
    return PersonasApi.getGarantiasGrupo(
      grupo_id,
      res => {
        commit("SET_GARANTIAS_GRUPO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getRelaciones({ commit }, persona_id) {
    return PersonasApi.getRelaciones(
      persona_id,
      res => {
        commit("SET_RELACIONES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getRelacionesGrupo({ commit }, grupo_credito_id) {
    return PersonasApi.getRelacionesGrupo(
      grupo_credito_id,
      res => {
        commit("SET_RELACIONES_GRUPO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  relacionar({ commit }, data) {
    return PersonasApi.relacionar(
      data,
      res => {
        commit("ADD_RELACION", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getCreditosConSaldoPersona({ commit }, persona_id) {
    return PersonasApi.getCreditosConSaldoPersona(
      persona_id,
      res => {
        commit("SET_CREDITOS_PERSONA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getPersonaCuentasBancarias({ commit }, persona_id) {
    return PersonasApi.getCuentasBancarias(
      persona_id,
      res => {
        res.data.push({
          id: null,
          cuenta: "Otra Cuenta",
          CLABE: "No aplica",
          instituciones_financieras_id: null,
          disabled: 0,
          moneda_id: 1,
          institucion: {
              id: null,
              nombre: "No aplica"
          }})
        commit("SET_CUENTAS_BANCARIAS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
