<template>
  <div
    class="columns is-vcentered mb0 has-background-white z-1 px-2 pt-5 md:mt-0 full"
  >
    <div class="column is-2">
      <router-link to="/">
        <div class="img-container logo">
          <b-image :src="logoimg" alt="" ratio="4by1" class="center" />
        </div>
      </router-link>
    </div>
    <div class="column is-5" v-if="busqueda" />
    <div id="searchBar" class="column is-5" v-else>
      <b-field>
        <b-input
          id="topBuscador"
          placeholder="Buscar..."
          type="search"
          v-model="search"
          icon-pack="fas"
          icon-right="search"
          class="search-input"
          icon-right-clickable
          @icon-right-click="searchValue(search)"
          @keyup.enter.native="searchValue(search)"
        >
        </b-input>
      </b-field>
    </div>
    <div class="column is-hidden-tablet-only is-2-desktop">
      <div class="has-text-grey-darker">
        <p class="text-xs">
          Sistema al día:
          {{ sofom.fecha_cierre | moment("DD-MM-YYYY") }}
        </p>
        <p class="text-xs">Zona horaria: {{ sofom.timezone }}</p>
        <p class="text-xs">Sucursal: {{ me.sucursal_activa.nombre }}</p>
      </div>
    </div>
    <div class="column is-3-tablet is-1-desktop">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light"
            rounded
            icon-left="user-secret"
            @click="buzonAnonimo()"
          />
        </div>
        <div class="column">
          <b-button
            type="is-light"
            rounded
            icon-right="question"
            @click="ayudaModal()"
          />
        </div>
      </div>
    </div>
    <div class="column is-2">
      <dropdown-user :config="config" :sofom="sofom" :me="me" />
    </div>
  </div>
</template>

<script>
import ModalAyuda from "@/components/layout/ModalAyuda";
// import { debounce } from "@/vendors/helpers";
import DropdownUser from "@/components/layout/DropdownUser";

export default {
  name: "topBarFull",
  props: {
    sofom: Object,
    config: Object,
    me: Object,
    busqueda: [String, null]
  },
  components: {
    DropdownUser
  },
  data() {
    return {
      search: null
    };
  },
  // watch: {
  //   search: debounce(function(val) {
  //     this.$emit("search", val);
  //   }, 500)
  // },
  methods: {
    searchValue(value) {
      this.$emit("search", value);
    },
    ayudaModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAyuda,
        hasModalCard: true,
        trapFocus: true
      });
    },
    //Abre el buzón anónimo y cierra sesión de capvital
    buzonAnonimo() {
      window.open(
        this.config.host + "/buzon_anonimo",
        "_blank",
        "location=no,height=700,width=600,scrollbars=no,status=no,titlebar=no,menubar=no, toolbar=no"
      );
      this.$store.dispatch("logOut", this.me).then(res => {
        if (res.data.success == true) {
          localStorage.removeItem("accessToken");
          window.location.replace(this.config.client);
        }
      });
    }
  },
  created() {
    if (this.$route.query && this.$route.query.search) {
      this.search = this.$route.query.search;
      this.searchValue(this.search);
    }
  },
  computed: {
    logoimg() {
      return this.sofom.logo && this.sofom.logo.path !== undefined && this.sofom.logo.path
        ? this.sofom.logo.path
        : "/images/logo-white.png";
    }
  }
};
</script>
